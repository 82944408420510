import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PaymentSetupComponent } from './payment-setup/payment-setup.component';
import { ManageFootplayerFeeService } from './manage-footplayer-fee.service';
import { MatTableDataSource } from '@angular/material/table';
import { untilDestroyed } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { StatusConfirmationComponent } from '@app/shared/dialog-box/status-confirmation/status-confirmation.component';
import { BulkPaymentSetupComponent } from './bulk-payment-setup/bulk-payment-setup.component';
import { FeeReminderComponent } from './fee-reminder/fee-reminder.component';
import { MpinSetupComponent } from './mpin-setup/mpin-setup.component';
import { MpinLoginComponent } from './mpin-login/mpin-login.component';
@Component({
  selector: 'app-manage-footplayer-fee',
  templateUrl: './manage-footplayer-fee.component.html',
  styleUrls: ['./manage-footplayer-fee.component.scss']
})
export class ManageFootplayerFeeComponent implements OnInit {
  member_type: string;
  displayedColumns: string[] = [
    'Player Name',
    'Parent',
    'Start Date',
    'End Date',
    'Fees',
    'Status',
    'Action'
  ];
  public dataSource = new MatTableDataSource([]);
  selectedValue: string = 'Due'; // Pre-select "Option 2"
  options = [
    { value: 'Due', viewValue: 'Due' },
    { value: 'Paid', viewValue: 'Paid' }
  ];
  selectedFields: any[] = [];
  constructor(
    public dialog: MatDialog,
    private _toastrService: ToastrService,
    private _manageFootPlayerFeeService: ManageFootplayerFeeService
  ) {}

  ngOnInit() {
    //this.mPinSetup();
    //this.mPinLogin();
    this.checkPaymentSetup();
  }
  ngOnDestroy() {}

  checkPaymentSetup() {
    this._manageFootPlayerFeeService.checkPaymentSetup().subscribe(response => {
      console.log('response check', response);

      if (response.data.status === false) {
        if (!response.data.mpinSet) {
          // Only call mPinSetup if mpinSet is false
          this.mPinSetup();
        }
        this.onAddFootPlayer();
      } else {
        this.manageFootPlayerFees();
      }
    });
  }

  modifySingleStatus(row: any) {
    console.log('Selected Row Dataa:', row);
    // Extract only the required fields
    this.selectedFields = [];
    const selectedRow = {
      player_user_id:
        row.player && row.player.user_id ? row.player.user_id : 'N/A',
      parent_user_id:
        row.parent && row.parent.parent_user_id
          ? row.parent.parent_user_id
          : 'N/A',
      academy_user_id:
        row.payment && row.payment.academy_user_id
          ? row.payment.academy_user_id
          : 'N/A',
      fee_status: row.status
    };

    this.selectedFields.push(selectedRow);

    this.onAddFootPlayer();
  }

  manageFootPlayerFees() {
    this._manageFootPlayerFeeService
      .manageFootplayerFees()
      // .pipe(untilDestroyed(this))
      .subscribe(response => {
        console.log('response check', response);

        this.dataSource = new MatTableDataSource(response.data.records);
      });
  }

  // Store multiple updated rows

  updateStatus(row: any) {
    console.log('Updated row:', row);

    // Function to format date to 'YYYY-MM-DD'
    const formatDateForSQL = (dateString: string): string => {
      if (!dateString || dateString === 'N/A') return 'N/A';
      const date = new Date(dateString);
      return !isNaN(date.getTime()) ? date.toISOString().split('T')[0] : 'N/A';
    };

    // Extract only the required fields
    const selectedRow = {
      player_user_id:
        row.player && row.player.user_id ? row.player.user_id : 'N/A',
      parent_user_id:
        row.parent && row.parent.parent_user_id
          ? row.parent.parent_user_id
          : 'N/A',
      academy_user_id:
        row.payment && row.payment.academy_user_id
          ? row.payment.academy_user_id
          : 'N/A',
      fee_status: row.status || 'N/A',
      fees: row.payment && row.payment.fees ? Number(row.payment.fees) : 0,
      start_date:
        row.payment && row.payment.start_date
          ? formatDateForSQL(row.payment.start_date)
          : 'N/A',
      end_date:
        row.payment && row.payment.end_date
          ? formatDateForSQL(row.payment.end_date)
          : 'N/A'
    };

    // Find if row already exists in the selectedFields array
    const existingIndex = this.selectedFields.findIndex(
      item => item.player_user_id === selectedRow.player_user_id
    );

    if (existingIndex !== -1) {
      // If exists, update it
      this.selectedFields[existingIndex] = { ...selectedRow };
    } else {
      // If not, add new row data
      this.selectedFields.push({ ...selectedRow });
    }

    console.log('Updated selectedFields array:', this.selectedFields);
  }

  bulkSetupPayments(): void {
    const dialogRef = this.dialog.open(BulkPaymentSetupComponent, {
      panelClass: 'addfootplayer',
      data: {
        options: { header: 'Add', buttonName: 'Submit' }
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        // this.selectedPage = 1;
        // this.filter.page_no = 1;
        // this.getTraningCenterList(this.userId, this.pageSize, 1);
      }
    });
  }

  feeReminder(): void {
    const dialogRef = this.dialog.open(FeeReminderComponent, {
      panelClass: 'addfootplayer',
      data: {
        options: { header: 'Add', buttonName: 'Submit' }
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        // this.selectedPage = 1;
        // this.filter.page_no = 1;
        // this.getTraningCenterList(this.userId, this.pageSize, 1);
      }
    });
  }

  changeFeeStatus() {
    console.log('Show selected fields =>', this.selectedFields);
    const dialogRef = this.dialog.open(StatusConfirmationComponent, {
      panelClass: 'statusconfirmation',
      data: {
        header: 'Please confirm',
        message: 'Do you want to Update Status?',
        acceptText: 'Yes',
        rejectText: 'No'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        console.log('inisde result === true');
        this._manageFootPlayerFeeService
          .changeFeeStatus(this.selectedFields)
          .pipe(untilDestroyed(this))
          .subscribe(
            response => {
              this._toastrService.success(
                `Success`,
                'Status Update successfully'
              );
            },
            error => {
              this._toastrService.error(`${error.error.message}`, 'Failed');
            }
          );
      }
    });
  }

  onAddFootPlayer(): void {
    console.log('!!!!', this.selectedFields);
    const dialogRef = this.dialog.open(PaymentSetupComponent, {
      // width: '99%',
      panelClass: 'addfootplayer',
      data: this.selectedFields
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.selectedFields = [];
        this.manageFootPlayerFees();
        // this.selectedPage = 1;
        // this.filter.page_no = 1;
        // this.getFootPlayerList();
      }
    });
  }

  mPinSetup(): void {
    const dialogRef = this.dialog.open(MpinSetupComponent, {
      panelClass: 'addfootplayer',
      data: {
        options: { header: 'Add', buttonName: 'Submit' }
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        // this.selectedPage = 1;
        // this.filter.page_no = 1;
        // this.getTraningCenterList(this.userId, this.pageSize, 1);
      }
    });
  }

  mPinLogin(): void {
    const dialogRef = this.dialog.open(MpinLoginComponent, {
      panelClass: 'addfootplayer',
      data: {
        options: { header: 'Add', buttonName: 'Submit' }
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'refresh') {
        // this.selectedPage = 1;
        // this.filter.page_no = 1;
        // this.getTraningCenterList(this.userId, this.pageSize, 1);
      }
    });
  }
}
