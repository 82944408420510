import { Component, OnInit, OnDestroy } from '@angular/core';
import { AssignTraningCenterTableConfig } from './assign-traning-center-table-conf';
import { PanelOptions } from '@app/shared/models/panel-options.model';
import { environment } from '@env/environment';
import {
  AssignTraningCenterService,
  GetPublicProfileDetailsResponseContext
} from './assign-traning-center.service';
import { untilDestroyed } from '@app/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteConfirmationComponent } from '@app/shared/dialog-box/delete-confirmation/delete-confirmation.component';
import { ToastrService } from 'ngx-toastr';
import { SelectionModel } from '@angular/cdk/collections';

//import { AddFootplayerComponent } from '../manage-footplayer/foot-player/add-footplayer/add-footplayer.component';
import { StatusConfirmationComponent } from '@app/shared/dialog-box/status-confirmation/status-confirmation.component';
import { SharedService } from '@app/shared/shared.service';
declare let gtag: Function;
export interface PeriodicElement {
  center_name?: string;
  start_time?: number;
  end_time?: number;
  coache_name?: number;
  opening_days?: string;
  status?: string;
}

@Component({
  selector: 'app-assign-traning-center',
  templateUrl: './assign-traning-center.component.html',
  styleUrls: ['./assign-traning-center.component.scss']
})
export class AssignTraningCenterComponent implements OnInit {
  public tableConfig: AssignTraningCenterTableConfig = new AssignTraningCenterTableConfig();
  // public dataSource = new MatTableDataSource([]);
  public dataSource = new MatTableDataSource<any>([]);
  dataSourceForAttendance: any[] = [];
  publicProfileData: GetPublicProfileDetailsResponseContext['data'];
  selectedRows: any[] = [];
  selectedUserIds: string[] = [];
  unSelectedUserIds: string[] = [];
  sidebar: boolean = false;
  filter: any = {};
  user_type = 'player';
  academy_user_id = '';
  tab = 'personal';
  assignedUserIds = [];
  pageSize: number = 50;
  data: any;
  pageNo: number = 1;
  selectedPage: number = 1;
  environment = environment;
  player_type: string;
  member_type: string;
  show_count: number;
  total_count: number;
  send_to = '';
  searchText = '';
  selection = new SelectionModel<PeriodicElement>(true, []);
  displayedColumns: string[] = ['name', 'email', 'phone', 'date', 'status'];
  list = [];
  userId: string;
  // LEFT PANEL
  panelOptions: Partial<PanelOptions> = {
    bio: true,
    member_type: true,
    my_achievements: true,
    view_profile_link: true,
    is_public: false
  };
  isPublic: boolean = false;

  filtersList = {
    position: true,
    playerCategory: true,
    age: true,
    location: true,
    strongFoot: true,
    teamTypes: true,
    ability: true,
    status: true
  };

  constructor(
    private _footPlayerService: AssignTraningCenterService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private _toastrService: ToastrService,
    private _sharedService: SharedService,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.userId = localStorage.getItem('user_id');
    this.user_type = localStorage.getItem('member_type');
    this.filter.page_size = this.pageSize;
    this.filter.page_no = this.pageNo;

    this.initializeSelection();
    this.getPlayerAttendanceDetails();
    this._activatedRoute.params.subscribe(param => {
      if (param.send_to) {
        this.send_to = param.send_to;

        if (this.user_type !== 'player') {
          this.getPublicProfileDetails();
          this.getPlayerAttendanceDetails();
          this.getTraningCenterList(this.userId, this.pageSize, 1);
        }
      }
    });
  }
  initializeSelection() {
    this.dataSource.data = this.dataSource.data.map(row => ({
      ...row,
      selected: false // Set selected to false initially
    }));
  }
  ngOnDestroy() {}

  openFilter() {
    this._sharedService.setFilterDisplayValue(true);
  }
  addFootplayerButtonClick() {
    gtag('event', 'add_footplayer_button_click', {
      event_category: 'Button',
      event_label: 'Add Footplayer Button',
      value: 1
    });
  }
  getMemberType(value: string) {
    this.member_type = value;
  }

  // Method to retrieve all selected rows
  getSelectedRows() {
    return this.selectedRows;
  }
  updatePage(event: any) {
    this.selectedPage = event.selectedPage;
    this.pageNo = this.selectedPage;
    this.filter.page_no = this.pageNo;
    this.getTraningCenterList(this.userId, this.pageSize, event.selectedPage);
  }
  getTraningCenterList(userid: string, page_size: number, page_no: number) {
    this._footPlayerService
      .traningCenterList(userid, this.send_to, { page_size, page_no })
      // .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.assignedUserIds = response.data.center_list.map(item => item);

        // Add `checked: false` to each object
        response.data.records = response.data.records.map(item => ({
          ...item,
          checked: false
        }));
        this.selectedRows = [this.assignedUserIds];
        this.dataSource = new MatTableDataSource(response.data.records);

        this.show_count = response.data.records.length;
        this.total_count = response.data.total;
      });
  }

  getPublicProfileDetails() {
    let data = { user_id: '' };
    data.user_id = this.send_to;

    this._footPlayerService
      .getPublicProfileDetails(data)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this.publicProfileData = response.data;

          this.data = { ...this.data, ...this.publicProfileData };
        },
        error => {
          this._toastrService.error('Error', error.error.message);
        }
      );
  }

  getPlayerAttendanceDetails() {
    let data = {
      user_id: this.user_type === 'player' ? this.userId : this.send_to,
      ...(this.user_type !== 'player' && { academy_user_id: this.userId })
    };

    this._footPlayerService.getPlayerAttendanceDetails(data).subscribe(
      response => {
        // Ensure response.data is an array
        if (Array.isArray(response.data)) {
          this.dataSourceForAttendance = response.data.map(item => {
            // Extract player data and details
            const playerData =
              item.player_data.length > 0 ? item.player_data[0] : null;
            const playerDetails = playerData || {};

            // Extract training center details
            const trainingCenterDetails = item.training_center_details || {};

            return {
              traning_center_name:
                trainingCenterDetails.traning_center_name || 'N/A',
              start_time: trainingCenterDetails.start_time || 'N/A',
              end_time: trainingCenterDetails.end_time || 'N/A',
              address: trainingCenterDetails.full_address || 'N/A', // Assuming `phone` is equivalent to `player_user_id`
              status: playerDetails.status || 'N/A',
              date: playerDetails.date || 'N/A'
            };
          });
        } else {
          this._toastrService.error('Invalid data format received from API');
        }
      },
      error => {
        // this._toastrService.error('Error', error.error.message);
      }
    );
  }
  getSearchText(value: string) {
    this.searchText = value;
    this.filter.search = this.searchText;
    this.filter.page_no = 1;
    this.selectedPage = 1;
    this.getTraningCenterList(this.userId, this.pageSize, 1);
  }
  // delete
  deleteFootplayerPopup(id: string) {
    console.log('inside delete footplayer', id);
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      panelClass: 'deletepopup',
      data: {
        header: 'Please confirm',
        message: 'Are you sure you want to delete?',
        acceptText: 'Yes',
        rejectText: 'No'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._footPlayerService
          .deleteFootPlayer(id)
          .pipe(untilDestroyed(this))
          .subscribe(
            response => {
              this._toastrService.success(
                `Success`,
                'FootPlayer deleted successfully'
              );
              this.selectedPage = 1;
              this.filter.page_no = 1;
              this.router.navigate(
                [
                  this.route.snapshot.queryParams.redirect ||
                    '/member/manage-footplayer'
                ],
                { replaceUrl: true }
              );
            },
            error => {
              // log.debug(`Login error: ${error}`);
              this._toastrService.error(
                `${error.error.message}`,
                'Delete Footplayer'
              );
            }
          );
      }
    });
  }
  // delete
  deletePopup(id: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      panelClass: 'deletepopup',
      data: {
        header: 'Please confirm',
        message: 'Are you sure you want to delete?',
        acceptText: 'Yes',
        rejectText: 'No'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._footPlayerService
          .deleteTraningCenter(id)
          .pipe(untilDestroyed(this))
          .subscribe(
            response => {
              this._toastrService.success(
                `Success`,
                'Training Center deleted successfully'
              );
              this.selectedPage = 1;
              this.filter.page_no = 1;
              this.getTraningCenterList(this.userId, this.pageSize, 1);
            },
            error => {
              // log.debug(`Login error: ${error}`);

              this._toastrService.error(
                `${error.error.message}`,
                'Delete Footplayer'
              );
            }
          );
      }
    });
  }

  resendInvitationPopup(email: string) {
    const dialogRef = this.dialog.open(StatusConfirmationComponent, {
      panelClass: 'statusconfirmation',
      data: {
        header: 'Please confirm',
        message: 'Do you want to Resend Invitation?',
        acceptText: 'Yes',
        rejectText: 'No'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._footPlayerService
          .resendFootPlayerInvite({ email })
          .pipe(untilDestroyed(this))
          .subscribe(
            response => {
              this._toastrService.success(
                `Success`,
                'Resend invite successfully'
              );
            },
            error => {
              this._toastrService.error(
                `${error.error.message}`,
                'Resend Invitation'
              );
            }
          );
      }
    });
  }

  getTab(value: string) {
    this.tab = value;
  }

  onChangeFilter(event: any) {
    if (event) {
      this.filter = event;
    } else {
      this.filter = {};
    }
    this.selectedPage = 1;
    this.filter.page_no = 1;
    this.filter.page_size = 10;
    this.getTraningCenterList(this.userId, this.pageSize, 1);
  }
  onCheckboxChange(event: Event, userId: string) {
    const checkbox = event.target as HTMLInputElement;
    if (!checkbox.checked) {
      if (!this.unSelectedUserIds.includes(userId)) {
        this.unSelectedUserIds.push(userId);
      }
    }
    if (this.assignedUserIds)
      if (checkbox.checked) {
        this.selectedUserIds.push(userId);
      } else {
        this.selectedUserIds = this.selectedUserIds.filter(id => id !== userId);
      }
  }

  // AddPlayerPopUp
  assignTrainingCenter() {
    const data = this.getSelectedRows();
    const minimalPayload = data.map((row: any) => ({
      center_user_id: row.user_id, // Or _id depending on your backend requirement
      academy_user_id: row.academy_user_id
    }));

    const dialogRef = this.dialog.open(StatusConfirmationComponent, {
      panelClass: 'statusconfirmation',
      data: {
        header: 'Assign Training Center',
        message: 'Do you want to assign Traning Center?',
        acceptText: 'Yes',
        rejectText: 'No'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this._footPlayerService
          .assignTrainingCenter(this.send_to, minimalPayload)
          .pipe(untilDestroyed(this))
          .subscribe(
            response => {
              this._toastrService.success(
                `Success`,
                'Training Center Assign successfully'
              );
            },
            error => {
              this._toastrService.error(`${error.error.message}`, 'Error');
            }
          );
      }
    });
  }

  handleAssignUnassign() {
    const academyUserId = localStorage.getItem('user_id');

    // Prepare payload for assigning players
    const assignPayload = this.selectedUserIds.map(playerId => ({
      center_user_id: playerId,
      academy_user_id: academyUserId
    }));

    // Prepare payload for unassigning players
    const unassignPayload = this.unSelectedUserIds.map(playerId => ({
      center_user_id: playerId,
      academy_user_id: academyUserId
    }));

    // Perform API calls for assign
    if (assignPayload.length > 0) {
      this._footPlayerService
        .assignTrainingCenter(this.send_to, assignPayload)
        .subscribe(
          response => {
            this._toastrService.success(
              'Players assigned successfully',
              'Success'
            );
          },
          error => {
            console.log('Error assigning players:', error);
            this._toastrService.error('Error assigning players', 'Error');
          }
        );
    }

    //Perform API calls for unassign
    if (unassignPayload.length > 0) {
      this._footPlayerService
        .unAssignTrainingCenter(this.send_to, unassignPayload)
        .subscribe(
          response => {
            this._toastrService.success(
              'Players unassigned successfully',
              'Success'
            );
          },
          error => {
            console.log('Error unassigning players:', error);
            this._toastrService.error('Error unassigning players', 'Error');
          }
        );
    }
  }
}
