import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { matchingPassword } from '@app/shared/validators/matchingPassword';
import { untilDestroyed } from '@app/core';
import { ManageFootplayerFeeService } from '../manage-footplayer-fee.service';

declare let gtag: Function;
@Component({
  selector: 'app-mpin-login',
  templateUrl: './mpin-otp.component.html',
  styleUrls: ['./mpin-otp.component.scss']
})
export class MpinOtpComponent implements OnInit, OnDestroy {
  createPasswordForm: FormGroup;
  token: string;
  isLinkExpired: boolean = false;
  tooltip: string = 'Please provide only number';

  constructor(
    private _formBuilder: FormBuilder,
    private _manageFootPlayerFeeService: ManageFootplayerFeeService,
    private _route: ActivatedRoute,

    private _toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.createForm();
    this._route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnDestroy() {}

  ngOnInit() {
    this._manageFootPlayerFeeService
      .sendOtp()
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (!response.data.status) {
            this.router.navigate(
              [this.route.snapshot.queryParams.redirect || '/mPinSetupPage'],
              { replaceUrl: true }
            );
          }
        },
        error => {
          this._toastrService.error('failed', 'mPin Not Matched');
        }
      );
  }

  optVerify() {
    console.log('---->', this.createPasswordForm.value);
    this._manageFootPlayerFeeService
      .optVerify(this.createPasswordForm.value)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.status == 'success') {
            this.router.navigate(
              [this.route.snapshot.queryParams.redirect || '/mPinSetupPage'],
              { replaceUrl: true }
            );
          }
        },
        error => {
          this._toastrService.error('failed', 'mPin Not Matched');
        }
      );
  }

  createForm() {
    this.createPasswordForm = this._formBuilder.group({
      otp: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{4}$/) // Allows only 4-digit numbers
        ]
      ]
    });
  }
}
