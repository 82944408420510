import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ManageFootplayerFeeService } from '../manage-footplayer-fee.service';
import { untilDestroyed } from '@app/core';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-payment-setup',
  templateUrl: './payment-setup.component.html',
  styleUrls: ['./payment-setup.component.scss']
})
export class PaymentSetupComponent implements OnInit, OnDestroy {
  public dataSource = new MatTableDataSource([]);
  paymentForm: FormGroup;
  environment = environment;
  pageSize: number = 5;
  selectedPage: number = 1;
  totalRecordSubject$ = new Subject();

  constructor(
    private _manageFootPlayerFeeService: ManageFootplayerFeeService,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PaymentSetupComponent>,
    private _toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.createForm();
  }

  ngOnDestroy() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}

  savePayment() {
    if (this.paymentForm.invalid) {
      this._toastrService.error('Please fill all required fields', 'Error');
      return;
    }
    if (
      (this.data &&
        !Array.isArray(this.data) &&
        Object.keys(this.data).length > 0) ||
      (Array.isArray(this.data) && this.data.length > 0)
    ) {
      console.log('---------', this.data);
      // Merge `this.data` with `this.paymentForm.value`
      let finalData = this.data.map((item: any) => ({
        ...item, // Spread existing data fields
        ...this.paymentForm.value // Merge form values
      }));
      console.log('final data is=>', finalData);
      this._manageFootPlayerFeeService
        .changeFeeStatus(finalData)
        .pipe(untilDestroyed(this))
        .subscribe(
          response => {
            this._toastrService.success(
              `Success`,
              'Status Update successfully'
            );
            this.dialogRef.close(true);
          },
          error => {
            this._toastrService.error(`${error.error.message}`, 'Failed');
          }
        );
    } else {
      console.log('in else save Payment Setup', this.paymentForm);
      this._manageFootPlayerFeeService
        .savePaymentSetup(this.paymentForm.value)
        .pipe(untilDestroyed(this))
        .subscribe(
          response => {
            this._toastrService.success(
              'Payment details saved successfully',
              'Success'
            );
            this.dialogRef.close(true);
          },
          error => {
            this._toastrService.error(`${error.error.message}`, 'Error');
          }
        );
    }
  }

  createForm() {
    this.paymentForm = this._formBuilder.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      fees: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]]
    });
  }
}
