import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { matchingPassword } from '@app/shared/validators/matchingPassword';
import { untilDestroyed } from '@app/core';
import { ManageFootplayerFeeService } from '../manage-footplayer-fee.service';

declare let gtag: Function;
@Component({
  selector: 'app-mpin-setup',
  templateUrl: './mpin-setup.component.html',
  styleUrls: ['./mpin-setup.component.scss']
})
export class MpinSetupComponent implements OnInit, OnDestroy {
  createPasswordForm: FormGroup;
  token: string;
  isLinkExpired: boolean = false;
  tooltip: string = 'Please provide only number';

  constructor(
    private _formBuilder: FormBuilder,
    private _manageFootPlayerFeeService: ManageFootplayerFeeService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toastrService: ToastrService
  ) {
    this.createForm();
    this._route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }

  ngOnDestroy() {}

  ngOnInit() {}

  setUpmPin() {
    console.log('---->', this.createPasswordForm.value);
    this._manageFootPlayerFeeService
      .setUpmPin(this.createPasswordForm.value)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.status == 'success') {
            this._router.navigate(
              [this._route.snapshot.queryParams.redirect || '/mPinLoginPage'],
              { replaceUrl: true }
            );
          }
        },
        error => {
          this._toastrService.error(`${error.error.message}`, 'Set mPin');
        }
      );
  }

  createForm() {
    this.createPasswordForm = this._formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(/^\d{4}$/) // Allows only 4-digit numbers
          ]
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(/^\d{4}$/) // Allows only 4-digit numbers
          ]
        ]
      },
      {
        validator: matchingPassword
      }
    );
  }
}
