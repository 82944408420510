import { Component, OnInit, Inject } from '@angular/core';
import { ManageFootplayerFeeService } from '../manage-footplayer-fee.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { untilDestroyed } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StatusConfirmationComponent } from '@app/shared/dialog-box/status-confirmation/status-confirmation.component';
@Component({
  selector: 'app-bulk-payment-setup',
  templateUrl: './bulk-payment-setup.component.html',
  styleUrls: ['./bulk-payment-setup.component.scss']
})
export class BulkPaymentSetupComponent implements OnInit {
  filter: any = {};
  paymentForm: FormGroup;
  pageSize: number = 350;
  pageNo: number = 1;
  show_count: number;
  total_count: number;
  selectedUserIds: string[] = [];
  unSelectedUserIds: string[] = [];
  assignedUserIds = [];
  center_id = '';
  center_name = '';
  selectedFields: any[] = [];
  selectedPage: number = 1;
  forEditTable: boolean = false;
  public dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = ['Player Name', 'Parent Name', 'Action'];

  constructor(
    private _manageFootPlayerService: ManageFootplayerFeeService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    public dialogRef: MatDialogRef<BulkPaymentSetupComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.center_id = data.user_id;
    this.center_name = data.center_name;
    this.createForm();
  }
  ngOnDestroy() {}
  ngOnInit() {
    this.filter.page_size = this.pageSize;
    this.filter.page_no = this.pageNo;
    this.PlayerList();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const fullName = `${data.first_name} ${data.last_name}`.toLowerCase();
      return (
        fullName.includes(filter) ||
        data.player_type.toLowerCase().includes(filter)
      );
    };
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
    this.dataSource.filter = filterValue;
  }

  PlayerList() {
    this._manageFootPlayerService
      .manageFootplayerFees()
      // .pipe(untilDestroyed(this))
      .subscribe(response => {
        console.log('response checkkkk', response);

        this.dataSource = new MatTableDataSource(response.data.records);
      });
  }

  // Update selectedUserIds when checkboxes are toggled
  onCheckboxChange(event: Event, row: any) {
    console.log('Event check:', event);
    const checkbox = event.target as HTMLInputElement;

    // Extract required fields without optional chaining
    const selectedRow = {
      player_user_id:
        row.player && row.player.user_id ? row.player.user_id : 'N/A',
      parent_user_id:
        row.parent && row.parent.parent_user_id
          ? row.parent.parent_user_id
          : 'N/A',
      academy_user_id:
        row.payment && row.payment.academy_user_id
          ? row.payment.academy_user_id
          : 'N/A',
      fee_status: row.status || 'N/A'
    };

    if (checkbox.checked) {
      // Add the record only if it's not already in the array
      if (
        !this.selectedFields.some(
          item => item.player_user_id === selectedRow.player_user_id
        )
      ) {
        this.selectedFields.push(selectedRow);
      }
    } else {
      // Remove the record when unchecked
      this.selectedFields = this.selectedFields.filter(
        item => item.player_user_id !== selectedRow.player_user_id
      );
    }

    console.log('Updated list:', this.selectedFields);
  }

  savePayment() {
    console.log('clickkk');
    console.log('--->', this.paymentForm.value);
    console.log('===>', this.selectedFields);
    this.selectedFields = this.selectedFields.map(item => ({
      ...item, // Spread existing properties
      ...this.paymentForm.value // Add new properties
    }));
    console.log('now selected fields', this.selectedFields);
    if (this.paymentForm.invalid) {
      this._toastrService.error('Please fill all required fields', 'Error');
      return;
    }
    this._manageFootPlayerService
      .changeFeeStatus(this.selectedFields)
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          this._toastrService.success(
            'Payment details saved successfully',
            'Success'
          );
          this.dialogRef.close(true);
        },
        error => {
          this._toastrService.error(`${error.error.message}`, 'Error');
        }
      );
  }

  createForm() {
    this.paymentForm = this._formBuilder.group({
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      fees: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]]
    });
  }
  updatePage(event: any) {
    console.log('pagenation click', event);
    this.selectedPage = event.selectedPage;

    this.filter.page_size = this.pageSize;
    this.pageNo = this.selectedPage;
    this.filter.page_no = this.pageNo;
    this.PlayerList();
  }
}
