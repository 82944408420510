import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomHttpParamEncoder } from '@app/shared/custom-http-param-encoder/custom-http-param-encoder.component';

const routes = {
  parentChildList: () => `/parent/child-list`,
  initiatePayment: () => `/payment/create-order`,
  verifyPayment: () => `/verify/payment`
  //verifyPayment: (order_id: string) => `/verify/payment/${order_id}`,
};

interface FindPlayerContext {
  name: string;
  email: string;
  phone: string;
}
interface FindPlayerResponseContext {
  status: string;
  message: string;
  data: {
    total: number;
    records: {
      user_id: string;
      avatar: string;
      name: string;
      member_type: string;
      category: string;
      position: string;
      is_verified: boolean;
      club_name: string;
      email: string;
      status: string;
    }[];
  };
}

interface GetFootPlayerListResponseContext {
  status: string;
  message: string;
  data: {
    footplayers: number;
    total: number;
    records: {
      id: string;
      user_id: string;
      avatar: string;
      category: string;
      name: string;
      position: string;
      status: string;
    }[];
  };
}

interface GetFootPlayerListContext {
  search?: string;
  page_no?: number;
  page_size?: number;
  footplayers?: number;
  position?: string;
  player_category?: string;
  age?: string;
  country?: string;
  state?: string;
  district?: string;
  strong_foot?: string;
  status?: string;
  ability?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ManageFootplayerFeeService {
  constructor(private httpClient: HttpClient) {}

  parentChildList(): Observable<any> {
    return this.httpClient.get<any>(routes.parentChildList());
  }

  initiatePayment(data: any): Observable<any> {
    return this.httpClient.post<any>(routes.initiatePayment(), data);
  }

  verifyPayment(): Observable<any> {
    return this.httpClient.get<any>(routes.verifyPayment());
    // verifyPayment(data: any, orderId: string): Observable<Blob> {
    // return this.httpClient.put<Blob>(routes.verifyPayment(orderId), data, {
    //   responseType: 'blob' as 'json' // 👈 Ensure response is treated as a Blob
    // });
  }
}
