import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from '@app/shared/static-data/static-data';
import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { ManageFootplayerFeeService } from './manage-footplayer-fee.service';
import { load } from '@cashfreepayments/cashfree-js';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-manage-payment',
  templateUrl: './manage-payment.component.html',
  styleUrls: ['./manage-payment.component.scss']
})
export class ManagePaymentComponent implements OnInit {
  @Input() clubAcademyType = '';
  @Input() user_id: string;
  member_type: string = localStorage.getItem('member_type') || 'player';
  allowedMemberTypes: string[] = ['player', 'academy', 'club', 'coach'];
  sidebar: boolean = false;
  pageSize: number = 10;
  currentPageNo: number = 1;
  selectedPage: number;
  feeDetails: any[] = [];
  player_type: string;
  totalAmount: number;
  grandTotal: number;
  gstAmount: number;
  platformFee: number;
  show_count: number;
  total_count: number;
  children: any[] = [];
  selectedUserId: string = null;
  cashfree: any;
  isPublic: boolean = false;
  userId: string;
  selectedFees: any[] = [];
  paymentHistoryData: any[] = [];
  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private _toastrService: ToastrService,
    private _formBuilder: FormBuilder,
    private _manageFootPlayerFeeService: ManageFootplayerFeeService,
    private sanitizer: DomSanitizer
  ) {}
  selectedTab: number = 0; // Default to the first tab
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['tab'] === 'history') {
        this.selectedTab = 1; // Assuming "History" is the second tab (index 1)
      }
    });

    this.parentChildList();
    this.paymentHistory();
  }

  ngOnDestroy() {}

  parentChildList() {
    this._manageFootPlayerFeeService
      .parentChildList()
      // .pipe(untilDestroyed(this))
      .subscribe(response => {
        console.log('response check', response);
        this.feeDetails = response.data.records;
        this.totalAmount = response.data.totalAmount;
        this.platformFee = response.data.platformFee;
        this.gstAmount = response.data.gstAmount;
        this.grandTotal = response.data.grandTotal;
      });
  }

  paymentHistory() {
    console.log('inside setTime out');
    this._manageFootPlayerFeeService.verifyPayment().subscribe(
      response => {
        console.log('response in payment history=>', response);

        this.paymentHistoryData = response.map(payment => {
          let pdfUrl: SafeResourceUrl = null; // Use SafeResourceUrl for security

          if (payment.pdf_invoice && payment.pdf_invoice.data) {
            try {
              // Convert byte array to Uint8Array, then to a valid Blob
              const byteArray = new Uint8Array(payment.pdf_invoice.data);
              const pdfBlob = new Blob([byteArray], {
                type: 'application/pdf'
              });

              // Generate an object URL
              const unsafeUrl = URL.createObjectURL(pdfBlob);

              // Sanitize URL to avoid unsafe:blob issue
              pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
            } catch (error) {
              console.error('Error generating PDF Blob:', error);
            }
          }

          return { ...payment, pdfUrl };
        });

        console.log('Processed Payment Data:', this.paymentHistoryData);
      },
      error => {
        this._toastrService.error('Payment Failed!');
        console.error('Error fetching payment history:', error);
      }
    );
  }

  async initializeSDK() {
    this.cashfree = await load({
      mode: 'sandbox',
      redirectTarget: '_self'
    });
  }
  sendSelectedData() {
    this.selectedFees = this.feeDetails
      .map(fee => ({
        ...fee,
        payment: fee.payment.filter(fees => fees.selected) // Only keep selected payments
      }))
      .filter(fee => fee.payment.length > 0); // Remove entries with no selected payments

    console.log('Selected Data:', this.selectedFees);
  }

  async initiatePayment() {
    this._manageFootPlayerFeeService
      .initiatePayment(this.selectedFees)
      .subscribe(async (response: any) => {
        if (response) {
          const paymentSessionId = response.data.payment_session_id;
          console.log('✅ Payment Session ID:', paymentSessionId);
          await this.initializeSDK();
          let checkoutOptions = {
            paymentSessionId: paymentSessionId,
            redirectTarget: '_self',
            returnUrl:
              'https://test.yftchain.com/member/manage-payment?tab=history'
          };
          console.log('order id=>', response.data.order_id);
          const checkPaymentStatus = this.cashfree.checkout(checkoutOptions);
          console.log('checkPaymentStatus', checkPaymentStatus);

          // 🔹 Verify payment status after some time
          // setTimeout(() => {
          //   console.log('inside setTime out');
          //   this._manageFootPlayerFeeService
          //     .verifyPayment(this.selectedFees, response.data.order_id)
          //     .subscribe(
          //       (pdfBlob: Blob) => {
          //         console.log('Received PDF Blob:', pdfBlob);

          //         // Ensure it's a valid Blob object
          //         if (!(pdfBlob instanceof Blob)) {
          //           console.error('Invalid Blob received:', pdfBlob);
          //           this._toastrService.error('Invalid PDF format received.');
          //           return;
          //         }

          //         // Convert Blob to Object URL
          //         const pdfUrl = URL.createObjectURL(pdfBlob);
          //         console.log('Generated PDF URL:', pdfUrl);

          //         // Option 1: Open PDF in a new tab
          //         window.open(pdfUrl, '_blank');

          //         // Option 2: Display PDF in an embedded viewer
          //         this.displayPdfInEmbeddedViewer(pdfUrl);

          //         // Optionally, trigger download
          //         const a = document.createElement('a');
          //         a.href = pdfUrl;
          //         a.download = `invoice_${response.data.order_id}.pdf`;
          //         a.click();
          //         URL.revokeObjectURL(pdfUrl);

          //         this._toastrService.success(
          //           'Payment Verified! Invoice Generated.'
          //         );
          //       },
          //       error => {
          //         this._toastrService.error('Payment Failed!');
          //         console.error('Error fetching PDF:', error);
          //       }
          //     );
          // }, 40000);
        }
      });
  }

  isFeeZero(fee: any): boolean {
    return fee.payment.some((p: any) => p.fees === 0);
  }

  // Helper method to display PDF in an embedded viewer
  displayPdfInEmbeddedViewer(pdfUrl: string) {
    // Create an iframe element
    const iframe = document.createElement('iframe');
    iframe.src = pdfUrl;
    iframe.width = '100%';
    iframe.height = '600px'; // Adjust height as needed
    iframe.style.border = 'none';

    // Append the iframe to a container in your template
    const pdfContainer = document.getElementById('pdf-container');
    if (pdfContainer) {
      pdfContainer.innerHTML = ''; // Clear previous content
      pdfContainer.appendChild(iframe);
    }
  }
}
