import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-return-ploicy',
  templateUrl: './return-ploicy.component.html',
  styleUrls: ['./return-ploicy.component.scss']
})
export class ReturnPloicyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
