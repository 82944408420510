import { Component, OnInit, Inject } from '@angular/core';
import { TraningCenterService } from '../traning-center.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { untilDestroyed } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { StatusConfirmationComponent } from '@app/shared/dialog-box/status-confirmation/status-confirmation.component';
@Component({
  selector: 'app-add-player',
  templateUrl: './add-player.component.html',
  styleUrls: ['./add-player.component.scss']
})
export class AddPlayerComponent implements OnInit {
  filter: any = {};
  pageSize: number = 350;
  pageNo: number = 1;
  show_count: number;
  total_count: number;
  selectedUserIds: string[] = [];
  unSelectedUserIds: string[] = [];
  assignedUserIds = [];
  center_id = '';
  center_name = '';

  selectedPage: number = 1;
  forEditTable: boolean = false;
  public dataSource = new MatTableDataSource([]);
  displayedColumns: string[] = [
    'serialNumber',
    'Avatar',
    'Player Name',
    'Category',
    'Action'
  ];

  constructor(
    private _traningCenterService: TraningCenterService,
    public dialog: MatDialog,
    private _toastrService: ToastrService,
    public dialogRef: MatDialogRef<AddPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.center_id = data.user_id;
    this.center_name = data.center_name;
  }
  ngOnDestroy() {}
  ngOnInit() {
    this.filter.page_size = this.pageSize;
    this.filter.page_no = this.pageNo;
    this.trainingCenterPlayerList();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const fullName = `${data.first_name} ${data.last_name}`.toLowerCase();
      return (
        fullName.includes(filter) ||
        data.player_type.toLowerCase().includes(filter)
      );
    };
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
    this.dataSource.filter = filterValue;
  }

  trainingCenterPlayerList() {
    this._traningCenterService
      .trainingCenterPlayerList(this.filter, this.data.user_id)
      .subscribe(response => {
        const defaultAvatar = 'assets/images/user-avatar.png'; // Path to default image
        const records = response.data.records.map(record => ({
          ...record,
          avatar: record.avatar || defaultAvatar // Add default avatar if missing
        }));
        this.assignedUserIds = response.data.AssignedplayersDetails.map(
          detail => detail.user_id
        );

        // Initialize selectedUserIds with already assigned users
        this.selectedUserIds = [...this.assignedUserIds];
        this.dataSource = new MatTableDataSource(records);
        this.show_count = response.data.records.length;
        this.total_count = response.data.total;
      });
  }

  // Update selectedUserIds when checkboxes are toggled
  onCheckboxChange(event: Event, userId: string) {
    const checkbox = event.target as HTMLInputElement;

    if (!checkbox.checked) {
      if (!this.unSelectedUserIds.includes(userId)) {
        this.unSelectedUserIds.push(userId);
      }
    }
    if (this.assignedUserIds)
      if (checkbox.checked) {
        this.selectedUserIds.push(userId);
      } else {
        this.selectedUserIds = this.selectedUserIds.filter(id => id !== userId);
      }
  }

  handleAssignUnassign() {
    const academyUserId = localStorage.getItem('user_id'); // Assuming academy user ID is stored in localStorage

    // Prepare payload for assigning players
    const assignPayload = this.selectedUserIds.map(playerId => ({
      player_user_id: playerId,
      academy_user_id: academyUserId
    }));

    // Prepare payload for unassigning players
    const unassignPayload = this.unSelectedUserIds.map(playerId => ({
      player_user_id: playerId,
      academy_user_id: academyUserId
    }));

    // Perform API calls for assign and unassign
    if (assignPayload.length > 0) {
      this._traningCenterService
        .assignTrainingCenter(this.center_id, assignPayload)
        .subscribe(
          response => {
            this.dialogRef.close('refresh');

            this._toastrService.success(
              'Players assigned successfully',
              'Success'
            );
          },
          error => {
            console.log('Error assigning players:', error);
            this._toastrService.error('Error assigning players', 'Error');
          }
        );
    }

    if (unassignPayload.length > 0) {
      this._traningCenterService
        .unAssignTrainingCenter(this.center_id, unassignPayload)
        .subscribe(
          response => {
            this.dialogRef.close('refresh');

            this._toastrService.success(
              'Players unassigned successfully',
              'Success'
            );
          },
          error => {
            console.log('Error unassigning players:', error);
            this._toastrService.error('Error unassigning players', 'Error');
          }
        );
    }
  }

  updatePage(event: any) {
    console.log('pagenation click', event);
    this.selectedPage = event.selectedPage;

    this.filter.page_size = this.pageSize;
    this.pageNo = this.selectedPage;
    this.filter.page_no = this.pageNo;
    this.trainingCenterPlayerList();
  }
}
