import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomHttpParamEncoder } from '@app/shared/custom-http-param-encoder/custom-http-param-encoder.component';

const routes = {
  getFootPlayerList: (query: string) => `/footplayers?${query}`,
  findPlayer: (query: string) => `/footplayer/search${query}`,
  savePaymentSetup: () => `/payment/setup`,
  setUpmPin: () => `/setup/mpin`,
  mPinLogin: () => `/mpin/login`,
  ismPinSet: () => `/payment/ismPinSet`,
  checkPaymentSetup: () => `/payment/setup-check`,
  manageFootplayerFees: () => `/manage/footplayer/fees`,
  manageFeesReminder: () => `/manage/fees/reminder`,
  changeFeeStatus: () => `/change/fee/status`,
  sendFeeReminder: () => `/send/fee/reminder`,
  trainingCenterPlayerList: (center_user_id: string, query: string) =>
    `/training-center/footplayers/${center_user_id}?${query}`,
  sendOtp: () => `/send-otp`,
  optVerify: () => `/Werify/otpp`
};

interface FindPlayerContext {
  name: string;
  email: string;
  phone: string;
}
interface FindPlayerResponseContext {
  status: string;
  message: string;
  data: {
    total: number;
    records: {
      user_id: string;
      avatar: string;
      name: string;
      member_type: string;
      category: string;
      position: string;
      is_verified: boolean;
      club_name: string;
      email: string;
      status: string;
    }[];
  };
}

interface GetFootPlayerListResponseContext {
  status: string;
  message: string;
  data: {
    footplayers: number;
    total: number;
    records: {
      id: string;
      user_id: string;
      avatar: string;
      category: string;
      name: string;
      position: string;
      status: string;
    }[];
  };
}

interface GetFootPlayerListContext {
  search?: string;
  page_no?: number;
  page_size?: number;
  footplayers?: number;
  position?: string;
  player_category?: string;
  age?: string;
  country?: string;
  state?: string;
  district?: string;
  strong_foot?: string;
  status?: string;
  ability?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ManageFootplayerFeeService {
  constructor(private httpClient: HttpClient) {}

  getFootPlayerList(
    context: GetFootPlayerListContext
  ): Observable<GetFootPlayerListResponseContext> {
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    Object.keys(context).forEach(key => {
      if (context[key]) httpParams = httpParams.append(key, context[key]);
    });
    return this.httpClient.get<GetFootPlayerListResponseContext>(
      routes.getFootPlayerList(httpParams.toString())
    );
  }

  checkPaymentSetup(): Observable<any> {
    return this.httpClient.get<any>(routes.checkPaymentSetup());
  }

  setUpmPin(data: any): Observable<any> {
    return this.httpClient.post<any>(routes.setUpmPin(), data);
  }
  optVerify(data: any): Observable<any> {
    return this.httpClient.post<any>(routes.optVerify(), data);
  }
  mPinLogin(data: any): Observable<any> {
    return this.httpClient.post<any>(routes.mPinLogin(), data);
  }
  manageFootplayerFees(): Observable<any> {
    return this.httpClient.get<any>(routes.manageFootplayerFees());
  }
  manageFeesReminder(): Observable<any> {
    return this.httpClient.get<any>(routes.manageFeesReminder());
  }

  ismPinSet(): Observable<any> {
    return this.httpClient.get<any>(routes.ismPinSet());
  }

  sendOtp(): Observable<any> {
    return this.httpClient.get<any>(routes.sendOtp());
  }
  savePaymentSetup(data: any): Observable<any> {
    return this.httpClient.post<any>(routes.savePaymentSetup(), data);
  }
  changeFeeStatus(data: any): Observable<any> {
    console.log('data in service is', data);
    return this.httpClient.post<any>(routes.changeFeeStatus(), data);
  }
  sendFeeReminder(data: any): Observable<any> {
    console.log('data in service is', data);
    return this.httpClient.post<any>(routes.sendFeeReminder(), data);
  }
  trainingCenterPlayerList(
    context: GetFootPlayerListContext,
    center_user_id: any
  ): Observable<GetFootPlayerListResponseContext> {
    let httpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    Object.keys(context).forEach(key => {
      if (context[key]) httpParams = httpParams.append(key, context[key]);
    });
    return this.httpClient.get<GetFootPlayerListResponseContext>(
      routes.trainingCenterPlayerList(center_user_id, httpParams.toString())
    );
  }
}
